import React, {useState, useRef, useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import promptr from "../util/api";
import {useSnackbar} from "notistack";
import _ from "lodash";
import Select from "react-select";

function User(props) {
    const {register, handleSubmit, formState, setValue, control} = useForm();
    const closeModalRef = useRef(null);
    const [result, setResult] = useState();
    const [user, setUser] = useState();
    const [topics, setTopics] = useState([]);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {
        checkProfile();
    }, [localStorage.getItem('profile')]);

    function checkProfile() {
        const item = JSON.parse(localStorage.getItem('profile'));
        if (item) {
            setUser(item);
            setTopics(item?.topics);
        }
    }


    const onSubmit = async (data) => {
        try {
            const response = await promptr.post("users/update", {
                first_name: data.first_name || user?.first_name,
                last_name: data.last_name || user?.last_name,
                email: data.email || user?.email,
                mobile: data.mobile || user?.mobile,
                team: data.team || user?.team_id,
                job_title: data.job_title || user?.job_title,
                include_activity_weekly_alert: data.include_activity_weekly_alert || false,
                topics:  topics.length > 0 ? topics.map(c => c.value) : [],
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });

                    localStorage.setItem('profile', JSON.stringify(responseBody.user));
                    props.onProfileUpdate(responseBody.user);
                    closeModalRef.current?.click();
                    checkProfile()
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('User response');
            console.log(responseBody);

        } catch (error) {
            console.log('User update error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        setResult(props?.options);
    }, []);


    const renderOptions = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={i?.is_default || defaultValue === i.id} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    return (
        <div id="user" className="modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md mb-3">
                                Update Profile
                            </h4>
                        </div>


                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">First name</label>
                            <div className="col-12">
                                <input defaultValue={user?.first_name || user?.full_name} {...register('first_name', {
                                    maxLength: 20
                                })} placeholder="First name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Last name</label>
                            <div className="col-12">
                                <input defaultValue={user?.last_name} {...register('last_name', {
                                    maxLength: 20
                                })} placeholder="Last name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Email address</label>
                            <div className="col-12">
                                <input defaultValue={user?.email}  {...register('email', {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })} placeholder="Email address" className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Mobile</label>
                            <div className="col-12">
                                <input defaultValue={user?.mobile} {...register('mobile', {
                                    maxLength: 20
                                })} placeholder="Mobile" className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Job Title</label>
                            <div className="col-12">
                                <input defaultValue={user?.job_title} {...register('job_title', {
                                    maxLength: 20
                                })} placeholder="Job Title" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-form-label col-form-label-sm">Team</label>
                                <div className="col-12">
                                    <select className="select w-full max-w-xs" {...register('team')}>
                                        <option disabled value='' selected> Pick one</option>
                                        {renderOptions(result?.teams, user?.team_id)}
                                    </select>
                                </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">

                                <div className="form-check">
                                    <input defaultChecked={true} {...register('include_activity_weekly_alert')}
                                           className="form-check-input" type="checkbox"
                                           value="1"/>
                                    <label className="form-check-label">
                                        Include MY activity in my weekly alert
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label
                                className="col-12 col-form-label col-form-label-sm">My specialisms</label>
                            <Controller
                                defaultValue={topics}
                                control={control}
                                name="topics"
                                render={({ field: { onChange, value, ref }}) => (
                                    <Select
                                        isSearchable={true}
                                        value={topics}
                                        placeholder="Choose specialisms"
                                        defaultValue={topics}
                                        onChange={function(val) {
                                            setValue('topics',val.map(c => c.value))
                                            setTopics(val)
                                        }}
                                        isMulti
                                        name="topics"
                                        options={result?.topics}
                                        className="db-multi-select"
                                        classNamePrefix="select"
                                        menuPlacement={'top'}
                                        noOptionsMessage={'No topics found'}
                                    />
                                )}/>

                        </div>

                    </div>

                    <div className="modal-action">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Update Profile </span></button>
                        <a ref={closeModalRef} href="#" className="btn btn-default btn-red">Close</a>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default User;
