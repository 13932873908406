import * as React from "react";
import {useEffect, useRef, useState} from "react";
import promptr from "../util/api";
import _ from "lodash";
import {useForm, Controller} from "react-hook-form";
import Select from 'react-select';
import {useSnackbar} from "notistack";


function CompanyUpdate(props) {

    const {register, handleSubmit, formState, control, setValue, reset} = useForm();
    const [result, setResult] = useState();
    const [company, setCompany] = useState([]);
    const [company_statuses, setCompanyStatuses] = useState([]);
    const [company_types, setCompanyTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    React.useEffect(() => {
        setCompany(props?.company);
    },[props?.company])


    const onSubmit = async (data) => {

        try {
            const response = await promptr.post("companies/" + company?.id + "/update", {
                name: data.name || company?.name,
                phone: data.phone || company?.phone,
               // organisation: data.organisation,
                website: data.website || company?.website,
                industry_sector: data.industry_sector || company?.industry_sector_id,
                comments: data.comments || company?.comments,
               // company_statuses: data.company_statuses || null,
               // tags: data.tags || null,
               // company_types: data.company_types || null,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    props?.onUpdate(company?.id);
                    reset();
                    closeModalRef.current?.click();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Company response');
            console.log(responseBody);

        } catch (error) {
            console.log('Company adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        // simulate async api call with set timeout
        getOptions().then();
    }, []);


    const getOptions = async () => {

        try {
            const response = await promptr.get("companies/options");
            if (response.status === 200) {
                let data = response.data;
                setResult(data);
                console.log('Options details loaded');
                console.log(data);
            }
        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }
    }

    const renderOptions = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={defaultValue === i.id} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    return (
        <div id="companyEdit" className="modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md mb-3">
                                COMPANY
                                DETAILS
                            </h4>
                        </div>

                        <div className="row mb-2">
                            <label
                                className="col-4 col-form-label col-form-label-sm">Name</label>
                            <div className="col-8">
                                <input
                                    defaultValue={company?.name} {...register('name', {
                                    maxLength: 50
                                })} placeholder="Company name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label
                                className="col-4 col-form-label col-form-label-sm">Phone</label>
                            <div className="col-8">
                                <input defaultValue={company?.phone} {...register('phone', {
                                    maxLength: 20
                                })} placeholder="Phone" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label
                                className="col-4 col-form-label col-form-label-sm">Website</label>
                            <div className="col-8">
                                <input type='text' defaultValue={company?.website}  {...register('website', {})} placeholder="Website address" className="form-control"/>
                            </div>
                        </div>
                        <div className="row  mb-2">
                            <label className="col-4 col-form-label col-form-label-sm">Industry Sector</label>
                            <div className="col-8">
                                <select defaultValue={company?.industry_sector_id}
                                        className="select w-full max-w-xs" {...register('industry_sector')}>
                                    <option disabled value='' selected> Pick one</option>
                                    {renderOptions(result?.industry_sectors, company?.industry_sector_id)}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Comments</label>
                            <div className="col-12">
                            <textarea {...register('comments')} defaultValue={company?.comments}
                                      className="form-control"
                                      placeholder="Write your comments"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="modal-action">
                            <button type='submit' disabled={isSubmitting}
                                    className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                                className="text-white">Save</span></button>
                            <a ref={closeModalRef} href="#" className="btn  btn-default btn-red">Close</a>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    );

}

export default CompanyUpdate;
