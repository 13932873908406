import * as React from "react";
import {useForm} from "react-hook-form";
import {useRef, useState} from "react";
import promptr from "../util/api";
import { useSnackbar } from 'notistack';

function Company (props) {

    const {register, handleSubmit, formState, reset} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onSubmit = async (data) => {
        try {
            const response = await promptr.post("companies/create", {
                company_name: data.company_name,
                organisation: data.organisation,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    reset({
                        company_name: null,
                        organisation: null,
                    });
                    closeModalRef.current?.click();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Contact adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

        return (
            <div id="company" className="modal">
                <div className="modal-box bg-white">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md mb-3">
                                Add a company
                            </h4>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Company name</label>
                            <div className="col-12">
                                <input defaultValue={props?.company}  {...register('company_name', {
                                    required: true,
                                    maxLength: 20
                                })} placeholder="Company name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Holding Organisation: Group name</label>
                            <div className="col-12">
                                <input {...register('organisation')} placeholder="Organisation name" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-action">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading cursor-pointer" : "btn  bg-green-500 btn-accent cursor-pointer"}><span
                            className="text-white">Add Company</span> </button>
                        <a ref={closeModalRef} href="#" className="btn btn-default btn-red cursor-pointer">Close</a>
                    </div>
                    </form>
                </div>


            </div>
        );
}

export default Company;
