import * as React from "react";
import {useEffect, useRef, useState} from "react";
import promptr from "../util/api";
import _ from "lodash";
import {useForm, Controller} from "react-hook-form";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {useSnackbar} from "notistack";


function Profile({companiesData, tagsData, contactData, options, onUpdate, isLoggedIn}) {

    const {register, handleSubmit, formState, control, setValue, reset, resetField} = useForm();
    const [result, setResult] = useState([]);
    const [tags, setTags] = useState([]);
    const [contact, setContact] = useState({});
    const [companies, setCompanies] = useState([]);
    const [isCompanyLoading, setIsCompanyLoading] = useState(false)
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const myForm = useRef(null);

    React.useEffect(() => {
        setCompanies(companiesData ?? []);
        setTags(tagsData);
        setContact(contactData);
        setContactValues(contactData);
        setResult(options);
    }, [tagsData, companiesData, contactData])


    const setContactValues = (data) => {
        reset({
            first_name: data?.first_name,
            last_name: data?.last_name,
            mobile: data?.mobile,
            linkedIn_profile_url: data?.linkedIn_profile_url,
            email: data?.email,
            notes: data?.notes,
            job_title: data?.job_title,
            address: data?.town_or_city,
            gpdr_consent: data?.gpdr_consent_id,
            contact_status: data?.contact_status_id,
            contact_type: data?.contact_type_id,
        });
    }

    const onSubmit = async (data) => {

        try {
            const response = await promptr.post("contacts/update", {
                first_name: data.first_name || contact?.first_name,
                last_name: data.last_name || contact?.last_name,
                email: data.email || contact?.email,
                contact: contact?.email,
                company: data.company,
                job_title: data.job_title,
                mobile: data.mobile,
                linkedIn_profile_url: data.linkedIn_profile_url,
                address: data.address,
                tags: data.tags || null,
                companies: data.companies || null,
                notes: data.notes || contactData?.notes,
                contact_type: data.contact_type || contactData?.contact_type_id,
                contact_status: data.contact_status || contactData?.contact_status_id,
                gpdr_consent: data.gpdr_consent || contactData?.gpdr_consent_id,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    // myForm.reset();
                    reset(data, {
                        keepValues: true,
                        keepDefaultValues: true,
                        keepDirty: true,
                    });
                    setContact(responseBody?.contact);
                    setContactValues(responseBody?.contact);
                    onUpdate(responseBody?.contact?.id);
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });

                    closeModalRef.current?.click();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Contact adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    const resetAllFields = () => {
        resetField('first_name');
        resetField('last_name');
        resetField('email');
        resetField('mobile');
        resetField('linkedIn_profile_url');
        resetField('job_title');
        resetField('gpdr_consent');
        resetField('address');
        resetField('tags');
        resetField('companies');
        resetField('notes');
        setCompanies([]);
        setTags([]);
        resetField('contact_status');
    }



    const getOptions = async () => {

        try {
            const response = await promptr.get("contacts/options");
            if (response.status === 200) {
                let data = response.data;
                setResult(data);
                console.log('Options details loaded');
                console.log(data);
            }
        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }
    }

    const submitNewCompany = async (value) => {
        try {
            const response = await promptr.post("companies/create", {
                company_name: value,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            if (response.status === 200) {
                let data = response.data;
                setResult(data.options);
                return data.company;
            }
        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }

        return false;
    }

    const handleCompanyCreate = async (inputValue) => {
        setIsCompanyLoading(true);
        const company = await submitNewCompany(inputValue);
        const items = companies;
        setIsCompanyLoading(false);
        if (company !== false) {
            console.log(companies);
            setCompanies((prev) => [...prev, company]);
            items.push(company);
            setValue('companies', items.map(c => c.value));
        }
    }

    const renderOptions = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={defaultValue === i.id} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    return (
        <div id="profile" className="modal promptr-modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)} ref={myForm}>
                    <div className="card " style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md mb-3">
                                CONTACT
                                DETAILS
                            </h4>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">First name</label>
                            <div className="col-12">
                                <input
                                    {...register('first_name', {
                                        maxLength: 20, value: contact?.first_name
                                    })} placeholder="First name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Last name</label>
                            <div className="col-12">
                                <input  {...register('last_name', {
                                    maxLength: 20, value: contact?.last_name
                                })} placeholder="Last name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Email</label>
                            <div className="col-12">
                                <input   {...register('email', {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }, value: contact?.email
                                })} placeholder="Email address" className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Phone(s)</label>
                            <div className="col-12">
                                <input  {...register('mobile', {value: contact?.mobile})}
                                        placeholder="Phone(s)"
                                        className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">LinkedIn URL</label>
                            <div className="col-12">
                                <input type='url'  {...register('linkedIn_profile_url', {value: contact?.linkedIn_profile_url})}
                                        placeholder="LinkedIn URL"
                                        className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Job title</label>
                            <div className="col-12">
                                <input
                                    {...register('job_title', {value: contact?.job_title})}
                                    placeholder="Job title" className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Town/City</label>
                            <div className="col-12">
                                <input
                                    {...register('address', {value: contact?.town_or_city})}
                                    placeholder="Town or City"
                                    className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">GPDR Consent</label>
                            <div className="col-12">
                                <select
                                    className="select w-full max-w-xs" {...register('gpdr_consent', {value: contact?.gpdr_consent_id})}>
                                    <option disabled value='' selected> Pick one</option>
                                    {renderOptions(result?.gpdr_consents, contactData?.gpdr_consent_id)}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Contact Status</label>
                            <div className="col-12">
                                <select
                                    className="select w-full max-w-xs" {...register('contact_status', {value: contact?.contact_status_id})}>
                                    <option disabled value='' selected> Pick one</option>
                                    {renderOptions(result?.contact_statuses, contactData?.contact_status_id)}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Contact Type</label>
                            <div className="col-12">
                                <select
                                    className="select w-full max-w-xs" {...register('contact_type', {value: contact?.contact_type_id})}>
                                    <option disabled value='' selected> Pick one</option>
                                    {renderOptions(result?.contact_types, contactData?.contact_type_id)}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Companies</label>
                            <div className="col-12">
                                <Controller
                                    control={control}
                                    name="companies"
                                    defaultValue={companiesData}
                                    render={({field: {onChange, value, ref}}) => (
                                        <CreatableSelect
                                            isSearchable={true}
                                            value={companies}
                                            isLoading={isCompanyLoading}
                                            isDisabled={isCompanyLoading}
                                            // defaultValue={props?.companies}
                                            placeholder="Choose companies"
                                            onChange={function (val) {
                                                console.log(val);
                                                setValue('companies', val.map(c => c.value))
                                                setCompanies(val)
                                            }}
                                            isMulti
                                            onCreateOption={handleCompanyCreate}
                                            formatCreateLabel={(inputValue) => `Add new company  '${inputValue}'`}
                                            createOptionPosition='first'
                                            name="companies"
                                            options={result?.companies}
                                            className="db-multi-select"
                                            classNamePrefix="select"
                                            menuPlacement={'top'}
                                            styles={{
                                                option: (provided, state) => {
                                                    if (state.data.__isNew__) {
                                                        return {...provided, color: "white", background: "grey"};
                                                    } else {
                                                        // Default styles for other options
                                                        return provided;
                                                    }
                                                },
                                            }}
                                            noOptionsMessage={({inputValue}) => !inputValue ? 'No options availabl' : "No results found"}
                                        />
                                    )}/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Tags & Lists</label>
                            <Controller
                                defaultValue={tagsData}
                                control={control}
                                name="tags"
                                render={({field: {onChange, value, ref}}) => (
                                    <Select
                                        isSearchable={true}
                                        value={tags}
                                        placeholder="Choose tags"
                                        //defaultValue={props?.tags}
                                        onChange={function (val) {
                                            console.log(val);
                                            setValue('tags', val.map(c => c.value))
                                            setTags(val)
                                        }}
                                        isMulti
                                        name="tag"
                                        options={result?.tags}
                                        className="db-multi-select"
                                        classNamePrefix="select"
                                        menuPlacement={'top'}
                                        noOptionsMessage={({inputValue}) => !inputValue ? 'No tags available' : "No results found"}
                                    />
                                )}/>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Notes</label>
                            <div className="col-12">
                              <textarea  {...register('notes', {value: contact?.notes})}
                                         className="form-control"
                                         placeholder="Write your notes"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-action card-bottom">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Save</span></button>
                        <a ref={closeModalRef} href="#" className="btn  btn-default btn-red">Close</a>
                    </div>

                </form>
            </div>

        </div>
    );

}

export default Profile;
