import * as React from "react";

export default class ContactRow extends React.Component {


    render() {
        const {contact} = this.props;
        return (
            <tr className="whitespace-nowrap " style={{borderBottom: '1px solid #3333331f'}}>
                <td width='30%' nowrap className="px-3 py-1 text-sm">
                    <a title='Click to view activity' onClick={() => this.props?.onContactSelected(contact?.email)} href="#" className="text-blue-100 text-decoration-line ml-2">
                        <span> {contact?.full_name} </span>
                    </a>

                </td>
                <td className="px-2 py-1" style={{wordWrap: 'break-word'}}>
                    <div className="text-sm text-gray-900">{contact?.email}</div>
                    <span className="activity-name text-gray-500">{contact?.mobile}</span>
                </td>
            </tr>
        );
    }
};
