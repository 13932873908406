import * as React from "react";
import {useForm} from "react-hook-form";
import {useRef} from "react";
import promptr from "../util/api";
import {useSnackbar} from "notistack";

function Email(props) {

    const {register, handleSubmit, formState} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onSubmit = async (data) => {
        try {
            const response = await promptr.post("users/email", {
                subject: data.subject,
                email: data.email,
                body: data.body
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    closeModalRef.current?.click();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Email response');
            console.log(responseBody);

        } catch (error) {
            console.log('Email adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    return (
        <div id="email" className="modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title text-center">
                            <h3>
                                Send an Email
                            </h3>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Subject</label>
                                <div className="col-12">
                                    <input   {...register('subject', {
                                        required: true,
                                        maxLength: 20
                                    })} placeholder="Email subject name" className="form-control"/>
                                </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Email</label>
                            <div className="col-12">
                                <input   {...register('email', {
                                    required: true,
                                })} placeholder="Email address" className="form-control"/>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <label className="col-form-label col-form-label-sm">Message</label>
                            <div className="col-12">
                                        <textarea rows={4}  {...register('body', {
                                            required: true,
                                        })} placeholder="Email body" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-action">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Send Email</span></button>
                        <a ref={closeModalRef} href="#" className="btn btn-default  btn-red">Close</a>
                    </div>
                </form>
            </div>


        </div>
    );
}

export default Email;
