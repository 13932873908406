import * as React from "react";
import {useForm} from "react-hook-form";
import {useRef} from "react";
import promptr from "../util/api";
import {useSnackbar} from "notistack";

function AddContact(props) {

    const {handleSubmit, formState} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = async (data) => {
        try {
            const response = await promptr.post("users/contact/" + props?.contact.id, {}, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });
            let responseBody = response.data;
            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    closeModalRef.current?.click();
                    props?.onUpdate(props?.contact?.email ?? props?.contact?.id);
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Add Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Add Contact error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    return (
        <div id="addContact" className="modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title text-center">
                            <h3>
                                Add as known contacts?
                            </h3>
                        </div>
                        <div className="row pt-3">
                            <h1 className={'text-center text-xl mb-4'}>
                                Do you want to add <b>{props?.contact?.first_name }</b> to your contacts, and be listed as knowing them ?
                            </h1>
                        </div>
                    </div>
                    <div className="modal-action">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Yes add me</span></button>
                        <a ref={closeModalRef} href="#" className="btn btn-default  btn-red">Discard</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddContact;
