import * as React from "react";
import {useEffect, useState} from "react";
import ActivityRow from "./ActivityRow";
import ReactPaginate from 'react-paginate';
import promptr from "../util/api";


function PaginatedUsers(props) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    // const [items, setItems] = useState(props?.items);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch items from another resources.
        // setItems(props?.items);
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(props.items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(props.items.length / itemsPerPage));
    }, [itemsPerPage, itemOffset, props.items]);

    // Invoke when user click to request another page.
    const handlePageClick = async (event) => {
        const newOffset = (event.selected * itemsPerPage) % props.items.length;
      //  await getData(event.selected);
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const getData = async (page) => {

        setIsLoading(true);
        const response = await promptr.get('activities?page=' + page);
        let responseBody = response.data;
        switch (response.status) {
            case 200:
                setCurrentItems(responseBody?.data);
                break;
            default:
                setCurrentItems(currentItems);
        }
        setIsLoading(false);
    }

    function Items({currentItems}) {
        return (
            <>
                {currentItems &&
                currentItems.map((item) => (
                    <ActivityRow activity={item} company={props?.from_company} options={props?.options}/>
                ))}
            </>
        );
    }

    return (
        <>
            <tbody className="bg-white">
            <Items currentItems={currentItems}/>
            </tbody>
            <tfoot className={'px-5'}>
            <tr>
                <td colSpan={2} className="text-center">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        containerClassName="pagination activities mt-3 mb-3"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </td>
            </tr>
            </tfoot>
        </>
    );
}

export default PaginatedUsers;
