import * as React from "react";
import SubscriptionPage from "../modals/Subscription";
import {useState} from "react";

function TrialAlert(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('Free Trial expires on 15-07-2023');

    React.useEffect(() => {
        setMessage(props?.message);
    }, [props?.message]);

    return (
        <>
            <div className="alert shadow-sm card alert-danger " id={'trialAlert'}
                 style={{
                     borderRight: 0,
                     marginBottom: 0,
                     borderLeft: 0,
                     borderTop: 0,
                     borderRadius: 0,
                     position: 'relative',
                     width: '100%',
                     left: 0,
                     right: 0
                 }}>
                <div style={{display: 'table'}}>
                    <h1 className="font-bold mb-1 d-none text-xl text-uppercase">FREE
                    TRIAL</h1>
                    <div className="font-bold">{message}</div>
                    <a onClick={() => setIsOpen(true)}
                       style={{minHeight: '2rem', height: '2rem', borderRadius: '3px', borderWidth: 1}}
                       className={'btn-danger d-none mt-2 btn-xs btn text-xs'}>Upgrade here</a>
                </div>
            </div>
            <SubscriptionPage isOpen={isOpen} onClose={() => setIsOpen(false)}/>
        </>
    );
}

export default TrialAlert;
