import App from "./components/App";
import {AppContainer} from "react-hot-loader";
import {initializeIcons} from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {SnackbarProvider} from 'notistack';
import Slide from '@material-ui/core/Slide';
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "Promptr";

const render = (Component) => {

    ReactDOM.render(
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            maxSnack={3} TransitionComponent={Slide}
        >
            <AppContainer>
                <Component title={title} isOfficeInitialized={isOfficeInitialized}/>
            </AppContainer>
        </SnackbarProvider>
        ,
        document.getElementById("container")
    );
};


/* Render application after Office initializes */
Office.onReady(() => {
    isOfficeInitialized = true;
    render(App);
}).then(r => {
    console.log("Office was not initialized");

    console.log(window.location.href);

    // check if request if coming from the control panel
    if (window.location.href.indexOf("tempo_token") !== -1) {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        sessionStorage.setItem('tempo_token',params.get('tempo_token'));
    }

    render(App);
});


/* Initial render showing a progress bar */

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(NextApp);
    });
}
