import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Sheet from "react-modal-sheet";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {useSnackbar} from "notistack";
import promptr from "../util/api";
import _ from "lodash";

function ActivityDetail(props) {
    const {register, handleSubmit, formState, control, setValue, reset} = useForm();
    const [activity, setActivity] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const closeModalRef = useRef(null);
    const {isSubmitting} = formState;
    const [result, setResult] = useState();
    const [isVisible, setIsVisible] = useState();
    const [isReminder, setIsReminder] = useState();
    const [isBid, setIsBid] = useState();
    const [isMeeting, setIsMeeting] = useState();
    const [activityType, setActivityType] = useState(null);
    const [isPipeline, setIsPipeline] = useState();
    const [topics, setTopics] = useState([]);
    const defaultDate = moment().format('dd-mm-yyyy');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const onError = (errors, e) => console.log(errors, e);


    React.useEffect(() => {
        setActivity(props?.activity);
        setIsOpen(props?.isOpen);
        setResult(props?.options);
        setActivityType(props.activity?.type);
        setTopics(props?.activity?.topics);
        initialiseFormInputs();
    }, [props?.activity, props?.isOpen, props.options]);


    const closeModal = () => {
        props?.closeSheetModal();
        setIsOpen(false);
    }

    const  initialiseFormInputs = () => {
        if (activityType != null) {
            setIsReminder(activityType?.is_a_reminder);
            setIsPipeline(activityType?.is_pipeline);
            setIsMeeting(activityType?.is_meeting);
            setIsBid(activityType?.is_bid);
            setIsVisible(activityType?.is_optional);
        }
    }

    const renderOptions = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={defaultValue === i.id} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    const onSubmit = async (data) => {
        try {


            console.log(data);

            const response = await promptr.post("activities/" + activity.id + "/edit", {
                summary: data.summary,
                activity_type: data.activity_type || activityType?.id,
                activity_date: data.activity_date || activity?.formatted_date,
                confidential: data.confidential || null,
                pipeline: data.pipeline || null,
                stage: data.stage || null,
                topics: data.topics,
                discussion: data.discussion || null,
                project: data.project || null,
                send_email_alert: data.send_email_alert || false,
                fee: data.fee,
                user: data.user || activity?.create_user_id,
                percentage_chance: data.percentage_chance,
                submission_date: data.submission_date,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });

                    props?.onActivityUpdate(responseBody.activity);
                    closeModal();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
                default:
                    enqueueSnackbar('An error occurred while sending your request, please reload the add in or consult our help center', {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Contact adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    const handleOptionalChange = (value, items) => {
        if (!_.isEmpty(items)) {
            setActivityType(_.find(items, ['id', parseInt(value)]))
            setIsVisible(_.find(items, {'is_optional': true, 'id': parseInt(value)}) !== undefined);
            setIsReminder(_.find(items, {'is_a_reminder': true, 'id': parseInt(value)}) !== undefined);
            setIsMeeting(_.find(items, {'is_meeting': true, 'id': parseInt(value)}) !== undefined);
            setIsBid(_.find(items, {'is_bid': true, 'id': parseInt(value)}) !== undefined);
            setIsPipeline(_.find(items, {'is_pipeline': true, 'id': parseInt(value)}) !== undefined);
        }
    }

    const filterBy = (option, inputValue) => {
        const reg = new RegExp(`^${inputValue}`);
        return reg.test(option.label);
    };


    return (
        <div className="col-12" id={'activity-' + activity?.id}>
            <Sheet isOpen={isOpen} ref={closeModalRef} onClose={closeModal}>
                <Sheet.Container>
                    <Sheet.Header>
                        <a href="#" ref={closeModalRef} onClick={closeModal}
                           className="btn btn-sm close-btn btn-circle absolute right-2 top-2 btn-close-company">✕</a>
                        <div className="row mt-3 pt-2">
                            <div className="display-block text-center mb-2">
                <span className="text-gray-600 text font-semibold">
                  ACTIVITY DETAILS </span>
                            </div>
                            <div className="display-block text-center mb-2">
                <span className="text-gray-600 text font-semibold">
                  {activity?.contact_name}</span>
                            </div>
                        </div>
                    </Sheet.Header>
                    <Sheet.Content>
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="card" style={{border: 'none'}}>
                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm text-danger">
                                        Type of Activity
                                    </label>
                                    <div className="col-12">
                                        <select onChange={(e) => {
                                            setValue('activity_type', e.target.value);
                                            handleOptionalChange(e.target.value, result?.activity_types);
                                        }} required={true}
                                                className="select w-full max-w-xs">
                                            <option disabled value='' selected> Pick one</option>
                                            {renderOptions(result?.activity_types, props?.activity?.type?.id)}
                                        </select>
                                    </div>
                                </div>
                                {activityType?.is_a_reminder || activityType?.is_meeting ?
                                    <div className="row mb-2 mt-1">
                                        <div className="col-12">

                                            <div className="form-check">
                                                <input
                                                    defaultChecked={activity?.notify_user} {...register('send_email_alert')}
                                                    className="form-check-input" type="checkbox"
                                                    value="1"/>
                                                <label className="form-check-label">
                                                    { activityType?.is_a_reminder ? 'Send pre-meeting briefing pack' : 'Send user alert on activity date'}
                                                </label>
                                            </div>
                                        </div>
                                    </div> : null}

                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm text-danger">Summary of
                                        Activity</label>
                                    <div className="col-12">
                                        <input  {...register('summary')} defaultValue={activity?.summary} type="text"
                                                placeholder="Summary of activity"
                                                className="form-control"/>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label htmlFor="activity_date"
                                           className="col-4 col-form-label col-form-label-sm">Activity Date</label>
                                    <div className="col-8">
                                        <Controller
                                            control={control}
                                            defaultValue={new Date(activity?.formatted_date)}
                                            name='activity_date'
                                            render={({field: {onChange, value, ref}}) => (
                                                <DatePicker
                                                    dateFormat="dd-MMM-yyyy"
                                                    className="form-control datePick"
                                                    placeholderText='Select activity date'
                                                    selected={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-form-label col-form-label-sm">Who did the BD</label>
                                    <div className="col-12">
                                        <select className="select w-full max-w-xs" {...register('user')}>
                                            <option disabled value='' selected> Pick user</option>
                                            {renderOptions(result?.users, activity?.create_user_id)}
                                        </select>
                                    </div>
                                </div>

                                {activityType?.is_bid ?
                                    <>
                                        <div className="row mb-2">
                                            <label className="col-form-label col-form-label-sm">Fee</label>
                                            <div className="col-12">
                                                <input defaultValue={activity?.fee ?? 0}  {...register('fee')} type="number"
                                                       placeholder="Fee"
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label className="col-form-label col-form-label-sm ">Percentage chance</label>
                                            <div className="col-12">
                                                <input max='100' defaultValue={activity?.percentage_chance ?? 0}  {...register('percentage_chance')} type="number"
                                                       placeholder="Percentage chance"
                                                       className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label className="col-form-label col-form-label-sm ">Submission Date</label>
                                            <div className="col-12">
                                                <Controller
                                                    control={control}
                                                    defaultValue={activity?.submission_date ? new Date(activity?.formatted_submission_date) : null}
                                                    name='submission_date'
                                                    render={({field: {onChange, value, ref}}) => (
                                                        <DatePicker
                                                            dateFormat="dd-MMM-yyyy"
                                                            className="form-control datePick"
                                                            placeholderText='Select submission date'
                                                            selected={value}
                                                            onChange={onChange}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </>

                                    : null }

                                {!activityType?.is_a_reminder ?

                                    <div className='col-12'>
                                        <div className="row mb-3">
                                            <label
                                                className="col-12 col-form-label col-form-label-sm">Topics</label>
                                            <Controller
                                                defaultValue={topics}
                                                control={control}
                                                name="topics"
                                                render={({field: {onChange, value, ref}}) => (
                                                    <Select
                                                        isSearchable={true}
                                                        value={topics}
                                                        placeholder="Choose topics"
                                                        defaultValue={topics}
                                                        onChange={function (val) {
                                                            console.log(val);
                                                            setValue('topics', val.map(c => c.value))
                                                            setTopics(val)
                                                        }}
                                                        isMulti
                                                        name="topics"
                                                        options={result?.topics}
                                                        className="db-multi-select"
                                                        classNamePrefix="select"
                                                        menuPlacement={'bottom'}
                                                        noOptionsMessage={({inputValue}) => !inputValue ? noOptionsText : "No results found"}
                                                    />
                                                )}/>

                                        </div>
                                        <div className="row col-12 mb-3">
                                            <label htmlFor="topics"
                                                   className="col-4 col-form-label col-form-label-sm">Projects</label>
                                            <div className="col-8">
                                                <select className="select w-full max-w-xs" {...register('project')}>
                                                    <option disabled value='' selected> Pick one</option>
                                                    {renderOptions(result?.projects, activity?.project?.id)}
                                                </select>
                                            </div>
                                        </div>
                                    </div> : null
                                }

                                {isVisible ?
                                    <div className='col-12'>
                                        {!activityType?.is_pipeline ?
                                            <div className="row col-12 mb-3">
                                                <label htmlFor="stage"
                                                       className="col-4 col-form-label col-form-label-sm">Bid
                                                    Stage</label>
                                                <div className="col-8">
                                                    <select className="select w-full max-w-xs" {...register('stage')}>
                                                        <option disabled value='' selected> Pick one</option>
                                                        {renderOptions(result?.stages, activity?.stage?.id)}
                                                    </select>
                                                </div>
                                            </div> : null}
                                        {!activityType?.is_bid ?
                                            <div className="row col-12 mb-3">
                                                <label htmlFor="stage"
                                                       className="col-4 col-form-label col-form-label-sm">Pipeline
                                                    Stages</label>
                                                <div className="col-8">
                                                    <select
                                                        className="select w-full max-w-xs" {...register('pipeline')}>
                                                        <option disabled value='' selected> Pick one</option>
                                                        {renderOptions(result?.pipelines, activity?.pipeline?.id)}
                                                    </select>
                                                </div>
                                            </div>
                                            : null}
                                        <div className="row col-12 mb-3">
                                            <label htmlFor="confidential"
                                                   className="col-4 col-form-label col-form-label-sm">Confidential</label>
                                            <div className="col-8">
                                                <select
                                                    className="select w-full max-w-xs" {...register('confidential')}>
                                                    <option disabled value='' selected> Pick one</option>
                                                    {renderOptions(result?.confidentials, activity?.confidential?.id)}
                                                </select>
                                            </div>
                                        </div>
                                    </div> : null}

                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm">Point of Discussion</label>
                                    <div className="col-12">
                            <textarea {...register('discussion')} defaultValue={activity?.notes}
                                      className="form-control"
                                      placeholder="Point of discussion"></textarea>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-action card-bottom">
                                <button type='submit' disabled={isSubmitting}
                                        className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                                    className="text-white">Save Changes</span></button>
                                <a href="#"  onClick={closeModal} className="btn btn-default btn-red">Discard</a>
                            </div>
                        </form>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
        </div>
    );
}

export default ActivityDetail;
