import * as React from "react";
import {useEffect, useState} from "react";
import ContactRow from "./ContactRow";
import ReactPaginate from 'react-paginate';


function PaginatedContact(props) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [items, setItems] = useState(props?.items);
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        setItems(props?.items);
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemsPerPage, itemOffset, items]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };


    function Items({currentItems}) {
        return (
            <>
                {currentItems &&
                currentItems.map((item) => (
                    <ContactRow onContactSelected={props?.onContactSelected} contact={item}/>
                ))}
            </>
        );
    }

    return (
        <>
            <tbody className="bg-white">
            <Items currentItems={currentItems}/>

            <tr>
                <td colSpan={2} className="text-center">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        containerClassName="pagination activities mt-3 mb-3"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                </td>
            </tr>
            </tbody>
        </>
    );
}

export default PaginatedContact;
