import * as React from "react";

function Referral() {

        return (
            <div className="modal" id="referral" style={{with: '57rem'}}>
                <div className="modal-box bg-white">
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md">
                                Engagement and Activities
                            </h4>
                        </div>

                        <div className="text-sm border-gray-200 ">
                            <table>
                                <thead>
                                <tr className="bg-green">
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        NAME
                                    </th>
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        TYPE
                                    </th>
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        USER
                                    </th>
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        ORGANISATION
                                    </th>
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        CONTACT
                                    </th>
                                    <th className="px-6 py-2 text-xs text-gray-500">
                                        DATE
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white">
                                <tr className="whitespace-nowrap  bg-gray-100">
                                    <td className="px-4 py-2 text-sm text-gray-500">
                                        Meeting
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="text-sm text-gray-900">
                                            John Smith had coffee with Simon
                                        </div>
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="text-sm text-gray-500">
                                            13/09/2021
                                        </div>
                                    </td>

                                </tr>
                                <tr className="whitespace-nowrap ">
                                    <td className="px-4 py-2 text-sm text-gray-500">
                                        Meeting
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="text-sm text-gray-900">
                                            John Smith had coffee with Simon
                                        </div>
                                    </td>
                                    <td className="px-4 py-2">
                                        <div className="text-sm text-gray-500">
                                            13/09/2021
                                        </div>
                                    </td>
                                    <td className="px-4 py-2">
                                        Midlands
                                    </td>
                                    <td className="px-4 py-2">
                                        Contact
                                    </td>
                                    <td className="px-4 py-2">
                                        Date
                                    </td>

                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Referral;
