import React, {useRef} from 'react';
import {useForm} from "react-hook-form";
import promptr from "../util/api";
import {useSnackbar} from "notistack";

function Contact(props) {
    const {register, handleSubmit, formState, reset, setValue} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {
       initialiseForm();
    }, [props?.contact_exists, props?.first_name])

    const initialiseForm = () => {
        if(props.contact_exists === false) {
            reset({
                first_name: props?.first_name,
                last_name: props?.last_name,
                email: props?.email,
                company: getDomain()
            });
        } else {
            reset({
                first_name: null,
                last_name: null,
                email:null,
                company:null,
                job_title:null,
            });
        }
    }

    const getDomain = () => {
        let email = props?.email;
        if(email != null) {
            let companyNames = _.replace(_.join(_.split(email.replace(/.*@/, ""), '.'), ' '));
            return _.startCase(_.replace(_.replace(companyNames.substring(0, companyNames.lastIndexOf(" ")),' co', ' '), '-', ' '));
        }
        return null;
    }


    const onSubmit = async (data) => {
        try {
            const response = await promptr.post("contacts/create", {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                company: data.company,
                job_title: data.job_title,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')},
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    reset({
                        first_name: null,
                        last_name: null,
                        email:null,
                        company:null,
                        job_title:null,
                    });
                    closeModalRef.current?.click();
                    props?.onSuccess(data.email || props?.contact?.id);
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Contact adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };
    return (
        <div id="contact" className="modal">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card row" style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md mb-3">
                                Whom do you want to add ?
                            </h4>
                        </div>


                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">First name</label>
                            <div className="col-12">
                                <input defaultValue={props?.contact_exists ? null : props?.first_name ?? props?.full_name} {...register('first_name', {
                                    maxLength: 20
                                })} placeholder="First name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Last name</label>
                            <div className="col-12">
                                <input defaultValue={props?.contact_exists ? null : props?.last_name} {...register('last_name', {
                                    maxLength: 20
                                })} placeholder="Last name" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Email address</label>
                            <div className="col-12">
                                <input defaultValue={props?.contact_exists ? null : props?.email }  {...register('email', {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })} placeholder="Email address" className="form-control"/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Job title</label>
                            <div className="col-12">
                                <input  {...register('job_title')} placeholder="Job title" className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Company</label>
                            <div className="col-12">
                                <input defaultValue={getDomain}  {...register('company')}  placeholder="Company" className="form-control"/>
                            </div>
                        </div>
                    </div>

                    <div className="modal-action">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent  loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Add Contact </span></button>
                        <a ref={closeModalRef} href="#" className="btn btn-default btn-red cursor-pointer">Close</a>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default Contact;
