import * as React from "react";
import {useEffect} from "react";
import promptr from "../util/api";

export default function ContactProfileCard({
                                               contact,
                                               contact_exists,
                                               is_loading,
                                               is_logged_in,
                                               known_by,
                                               engagement_score_color,
                                               engagement_score_percentage,
                                               engagement_score,
                                               openNewEmailDialog,
                                               openBrowserLink,
                                               getCompanyDetails,
                                               companies,
                                               company_name

                                           }) {


    useEffect(() => {

    }, [contact, is_logged_in])


    const renderCompanies = () => {
        return companies != null ? companies.map((company) => {
            return (
                <a className='text-blue-500 mr-2 text-decoration-underline' href="javascript:void(0)"
                   onClick={() => getCompanyDetails(company?.id)}>{company?.name},</a>
            )
        }) : <span className="text-black-300">{company_name}</span>;
    }

    return (
        <div className="card bg-gradient shadow-md">
            <div className="card-body p-4 pt-3 pb-0">
                <div className="row">
                    <div className="col-12">
                        <div className="display-block">
                            {contact_exists ?
                                <div className="d-block">
                                    <a href="#profile"
                                       className="text-blue-800 text-decoration-underline  text-md font-semibold">
                                        <span id="contactName">{contact?.full_name}</span>
                                        {!is_loading ? <span
                                            className="material-icons ml-2 text-black md-18">edit</span> : ''}
                                    </a>
                                    {is_logged_in && !contact?.is_my_contact ?
                                        <a href="#addContact"
                                           className="text-blue-800 text-decoration-underline  text-xs font-semibold float-end">Add
                                            I know them</a> : ''}
                                </div>
                                :
                                <a href="#"
                                   className="text-black  text-md font-semibold">
                                    <span id="contactName">{contact?.full_name}</span>
                                </a>
                            }
                        </div>
                        <div className="display-block text-sm">
                            <b className='break'>
                                {contact?.job_title}
                            </b>
                            {renderCompanies()}
                        </div>
                        <address className="mt-3 text-sm break">
                            <a className='break text-blue-400 text-decoration-underline' href="#"
                               onClick={async () => await openNewEmailDialog(contact?.email)}>{contact?.email}</a>
                            <a href={'tel:' + contact?.mobile} className="text-black"><span
                                id="contactEmail">{contact?.mobile}</span></a>
                            {contact?.linkedIn_profile_url != null ?
                                <a href='#'
                                   onClick={async () => await openBrowserLink(contact?.linkedIn_profile_url)}
                                   className="break text-blue-400 text-decoration-underline"><span
                                    id="contactLinkedln">LinkedIn</span></a> : ''}
                        </address>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 text-center p-1">
                        <a href="#activity" className="display-block text-sm">
                            <div className="display-block bg-green-500 p-2 rounded-xl">
                                <span className="text-white ">Add Note </span>
                            </div>
                        </a>
                    </div>
                    <div className="col-6 text-center p-1">
                        <a href="#contact" className="display-block text-sm">
                            <div className="display-block bg-green-500 p-2 rounded-xl">
                                                    <span
                                                        className="text-white">{contact_exists ? 'Add Contact' : 'Add Contact'} </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="divider mb-0 pb-0"/>
            <div className="card-body p-4 pt-0 pb-2 mb-0 flex flex-row w-full ">
                <div className="h-38 row">
                    <div className="display-block mb-2">
                        <span className="text-black-400 text-xs font-semibold">KNOWN BY </span> { contact_exists && (
                        ( <small> [ ENGAGEMENT =  <span
                            style={{backgroundColor: engagement_score_color ?? 'white'}}
                            className="text-xs px-2 py-1 rounded-md text-uppercase font-bold bg-green-500 text-white ">
                            {engagement_score}</span> ] </small> )
                    )}
                    </div>
                    <div className="display-block mb-2">
                                            <span className="mr-2 text-xs   text-black-300 ">{known_by != null ? known_by.join(', ') : 'No known contact'}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
