import * as React from "react";
import {useForm} from "react-hook-form";
import {useRef} from "react";
import promptr from "../util/api";
import OtpInput from 'react-otp-input';
import { useSnackbar } from 'notistack';

function Login(props) {
    const {handleSubmit, formState} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const [otp, setOtp] = React.useState('');
    const { enqueueSnackbar} = useSnackbar();

    const onSubmit = async (data) => {
            try {
                const response = await promptr.post("auth/login", {
                    email: props?.email,
                    otp: otp,
                });

                let responseBody = response.data;
                switch (response.status) {
                    case 200:
                        props.login(responseBody);
                        setOtp('');
                        closeModalRef.current?.click();
                        break;
                    case 422:
                        enqueueSnackbar(responseBody.message, {
                            variant: 'error',
                        });
                        break;
                }
                console.log('Email response');
                console.log(responseBody);

            } catch (error) {
                console.log('Email adding error');
                console.log(error);
                enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                    variant: 'error',
                });
            }
        };

    return (
        <>
            <div id="login" className="modal modal-login">
                <div className="modal-box bg-white">
                    <a href="#" ref={closeModalRef}  className="btn btn-sm close-btn btn-circle absolute right-2 top-2">✕</a>
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="card row" style={{border: 'none'}}>
                            <div className="card-title text-center d-block text-uppercase mb-4">
                                <p className="text-center justify-center mt-1 mb-4">
                                    <img width="140" className='d-inline' src="/assets/promptr.png"/>
                                </p>
                                <h3 className={'text-center text-uppercase login-title'}>
                                     SIGN IN
                                </h3>
                            </div>

                            <div className="row mb-2">
                                <h1 className={'text-center text-xl mb-4'}>
                                    Hello <b>{props?.user_name != null ? props.user_name.split(' ')[0] : ''}</b>, please
                                    enter your sign in code to login
                                </h1>

                                <div className="col-12">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        inputStyle={'otp-inputStyle'}
                                        separator={<span>-</span>}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="modal-action" style={{justifyContent: 'center'}}>
                            <button type='submit' disabled={isSubmitting}
                                    className={isSubmitting ? "btn w-100 bg-green-500 btn-accent loading" : "btn w-100 bg-green-500 btn-accent"}><span
                                className="text-white">Verify & Login</span></button>
                        </div>
                    </form>
                    <br/>
                    <div className='text-center'>
                        <a href="https://promptr.info/contact/" target="_blank"
                           className="mr-3 text-decoration-underline">Contact
                            Support for your security PIN</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
