import PaginatedActivity from "./PaginatedActivity";
import * as React from "react";
import {useEffect, useState} from "react";
import promptr from "../util/api";
import Skeleton from 'react-loading-skeleton'

export default function UserActivity({contact, options}) {

    const [emailSummary, setEmailSummary] = useState(null);
    const [meetingSummary, setMeetingSummary] = useState(null);
    const [events, setEvents] = useState([]);
    const [mailshots, setMailshots] = useState([]);
    const [bd, setBd] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contact !== undefined && contact !== null) {
            getActivitySummary().then(() => null);
        }
    }, [contact])


    const getActivitySummary = async () => {
        try {
            setLoading(true);
            const response = await promptr.get("contacts/" + contact.id + '/summary');
            if (response.status === 200) {
                let data = response.data;
                setEmailSummary(data?.email_summary);
                setMeetingSummary(data?.meeting_summary);
                setMailshots(data?.mailshot_summaries);
                setEvents(data?.event_summaries);
                setBd(data?.bd_summaries);
                setLoading(false);
            }
        } catch (error) {
            console.log('Summary details error');
            console.log(error);
            setLoading(false);
        }
    }

    const renderItems = (items, empty_message = 'No activities found') => {
        return items.length > 0 ? <div>
            {items.map((i) => <div className="text-md mb-1">
                {i}
            </div>)}
        </div> :  <p className={'text-muted text-center'}>{empty_message}</p>}

    return (
        <div className="row mt-3">
            <div className="card bg-gradient shadow-md p-0">
                <div className="card-body p-0 pt-3">
                    <div className="row">
                        <div className="display-block text-center mb-0">
                            <span
                                className="text-gray-600 text-sm font-semibold">Outlook emails + meetings (last 6mths)</span>
                        </div>
                    </div>

                    <div className="text-sm border-gray-200 table-responsive px-3 py-1">
                        {loading ?
                            <Skeleton baseColor='#f3f3f3' height={25} count={2}/>
                            : <>
                                <div className="text-md mb-2">
                                    {emailSummary ?? 'No data found'}
                                </div>
                                <div className={'text-lg'}>
                                    {meetingSummary ?? 'No data found'}
                                </div>
                            </>
                        }
                    </div>
                    <div className={'row'}>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="display-block text-center mb-0">
                            <span
                                className="text-gray-600 text-sm text-uppercase font-semibold">Marketing</span>
                        </div>
                    </div>
                    <div className="text-sm border-gray-200 table-responsive px-3 py-0">
                        {renderItems(events, 'No events activities found')}
                    </div>
                    <div className="text-sm border-gray-200 table-responsive px-3 py-0">
                        {renderItems(mailshots, 'No mailshot activities found')}
                    </div>
                    <div className={'row'}>
                        <hr/>
                    </div>
                    <div className="row mt-1">
                        <div className="display-block text-center mb-0">
                            <span
                                className="text-gray-600 text-sm text-uppercase font-semibold">BD</span>
                        </div>
                    </div>
                    <div className="text-sm border-gray-200 table-responsive px-3 py-0 pb-2">
                        {renderItems(bd, 'No BD activities found')}
                    </div>
                </div>
            </div>
        </div>
    )
}
