import * as React from "react";
import {useRef, useState} from "react";
import Sheet from "react-modal-sheet";
import promptr from "../util/api";
import {useSnackbar} from "notistack";

function SubscriptionPage(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const closeModalRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        setIsOpen(props?.isOpen);
    }, [props?.isOpen]);

    const closeModal = () => {
        props?.onClose();
        setIsOpen(false);
    }

    const getPlans = async ()  => {

        try {
            const response = await promptr.get("subscriptions", {}, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });
            let responseBody = response.data;
            switch (response.status) {
                case 200:
                    setSubscriptions(responseBody);
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Get subscriptions response');
            console.log(responseBody);

        } catch (error) {
            console.log('Get subscriptions error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    }

    return (
        <div className="col-12">
            <Sheet isOpen={isOpen} onOpenStart={ async () => await getPlans()} onClose={closeModal}>
                <Sheet.Container>
                    <Sheet.Header>
                        <a href="#" ref={closeModalRef} onClick={closeModal}
                           className="btn btn-sm close-btn btn-circle absolute right-2 top-2 btn-close-company">✕</a>
                        <div className="row mt-3 pt-2">
                            <div className="display-block text-center mb-2">
                <span className="text-gray-600 text font-semibold">
                  SUBSCRIBE TO PROMPTR </span>
                            </div>
                        </div>
                    </Sheet.Header>
                    <Sheet.Content>

                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
        </div>
    );
}

export default SubscriptionPage;
