import * as React from "react";
import promptr from "../util/api";
import _ from 'lodash';
import {useForm, Controller} from "react-hook-form";
import {useEffect, useRef, useState} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useSnackbar} from "notistack";
import Select from "react-select";


function Activity(props) {
    const {register, handleSubmit, formState, control, setValue, reset, watch} = useForm();
    const closeModalRef = useRef(null);
    const onError = (errors, e) => console.log(errors, e);
    const {isSubmitting} = formState;
    const [result, setResult] = useState();
    const [projects, setProjects] = useState();
    const [isVisible, setIsVisible] = useState();
    const [isHidden, setIsHidden] = useState();
    const [isReminder, setIsReminder] = useState();
    const [isBid, setIsBid] = useState();
    const [isMailshot, setIsMailshot] = useState();
    const [isEvent, setIsEvent] = useState();
    const [isMeeting, setIsMeeting] = useState();
    const [isPipeline, setIsPipeline] = useState();
    const [inCompany, setInCompany] = useState(false);
    const [company, setCompany] = useState();
    const [companies, setCompanies] = useState();
    const [topics, setTopics] = useState([]);
    const [activityType, setActivityType] = useState(null);
    const [contactLoading, setContactLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [contactResult, setContactResult] = useState([]);
    const [userResult, setUserResult] = useState([]);
    const defaultDate = moment().format('dd-mm-yyyy');
    const contact = register('contact');
    const user = register('user');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const contactInputRef = useRef();

    useEffect(() => {
        getOptions().then()
        setValue('contact', props?.contact_email);
        setCompanies(props.companies);
        resetForm();
        setSelectedCompany(props.companies);
        setInCompany(props?.is_company);
    }, [props.contact_email, props.companies, props.is_company, props?.contact]);


    useEffect(() => {
        if (!_.isEmpty(result?.projects)) {
            if (isEvent) {
                setProjects(_.filter(result?.projects, {is_event: true}))
            } else if (isMailshot) {
                setProjects(_.filter(result?.projects, {is_mailshot: true}))
            } else {
                setProjects(result?.projects);
            }
        }

    }, [isEvent, isMailshot])

    const resetForm = () => {
        reset({
            summary: null,
            activity_type: activityType?.id,
            // activity_date: defaultDate,
            contact: props?.contact_email,
            confidential: null,
            pipeline: null,
            stage: null,
            topic: null,
            topics: null,
            fee: 0,
            submission_date: null,
            percentage_chance: 0,
            // user: null,
            discussion: null,
            project: null,
            send_email_alert: false,
            company: null,
            is_private: false,
        });


        if (activityType != null) {
            setValue('activity_type', activityType?.id);
            handleOptionalChange(activityType?.id, result?.activity_types);
            setIsReminder(activityType?.is_a_reminder);
            setIsPipeline(activityType?.is_pipeline);
            setIsMeeting(activityType?.is_meeting);
            setIsMailshot(activityType?.is_mailshot);
            setIsEvent(activityType?.is_event);
            setIsHidden(activityType?.is_hidden);
            setIsBid(activityType?.is_bid);
            setIsVisible(activityType?.is_optional);
        }

        if (!inCompany) {
            contactInputRef.current.clear();
            setContactResult([]);
        }
    }

    const setOption = async (data) => {
        setResult(data);
        if (activityType == null) {
            if (data?.activity_types != null) {
                setActivityType(_.find(data?.activity_types, {'is_default': true}));
                setIsVisible(_.find(data?.activity_types, {'is_optional': true}) !== undefined);
                console.log(activityType);
            }
        }
        setProjects(data?.projects);
        console.log('Options details loaded');
        console.log(data);
    }

    const getOptions = async () => {

        try {

            const profile = localStorage.getItem('profile');
            await promptr.get("contacts/options?user=" + profile?.email, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')},
            }).then((response) => {
                if (response.status === 200) {
                    setOption(response.data);

                }
            });

        } catch (error) {
            console.log('Options details error');
            console.log(error);
        }
    }

    /**
     *  Validate form and return messages
     *
     * @returns {boolean}
     */
    const isValid = () => {
        return true;
    }

    const renderCompanies = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={defaultValue === i.id || _.size(options) === 1} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    const renderContacts = (options, defaultValue = null) => {
        return !_.isEmpty(options) ? options.map(function (i) {
            return (<option selected={defaultValue === i.email || _.size(options) === 1}
                            value={i.email}>{i.full_name}</option>)
        }) : <></>
    }

    const renderOptions = (options, defaultValue = null) => {

        let items = _.reject(options, function (i) {
            return i?.is_hidden
        });
        return !_.isEmpty(items) ? items.map(function (i) {
            return (<option selected={i?.is_default || defaultValue === i.id} value={i.id}>{i.name}</option>)
        }) : <></>
    }

    const onSubmit = async (data) => {
        try {

            let defaultActivityType = _.find(result?.activity_types, function (i) {
                return i.is_default;
            });

            console.log(data);

            const response = await promptr.post("activities/create", {
                summary: isMailshot ? 'Mailshot Activity' : (isEvent ? 'Event Activity' : data.summary),
                contact: data.contact || props?.contact_email,
                activity_type: data.activity_type || defaultActivityType?.id,
                activity_date: data.activity_date || defaultDate,
                confidential: data.confidential || null,
                pipeline: data.pipeline || null,
                stage: data.stage || null,
                topics: data.topics,
                company: data.company || company,
                user: data.user,
                fee: data.fee,
                percentage_chance: data.percentage_chance,
                submission_date: data.submission_date,
                discussion: data.discussion || null,
                project: data.project || null,
                send_email_alert: data.send_email_alert || false,
                is_private: data.is_private || false,
            }, {
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
            });

            let responseBody = response.data;

            switch (response.status) {
                case 200:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'success',
                    });
                    props?.onUpdate(data.contact || props?.contact_email);
                    resetForm();
                    closeModalRef.current?.click();
                    break;
                case 422:
                    enqueueSnackbar(responseBody.message, {
                        variant: 'error',
                    });
                    break;
                default:
                    enqueueSnackbar('An error occurred while sending your request, please reload the add in or consult our help center', {
                        variant: 'error',
                    });
                    break;
            }

            console.log('Contact response');
            console.log(responseBody);

        } catch (error) {
            console.log('Contact adding error');
            console.log(error);
            enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
                variant: 'error',
            });
        }
    };

    const handleOptionalChange = (value, items) => {
        if (!_.isEmpty(items)) {
            setIsMailshot(_.find(items, {'is_mailshot': true, 'id': parseInt(value)}) !== undefined);
            setIsEvent(_.find(items, {'is_event': true, 'id': parseInt(value)}) !== undefined);
            setIsVisible(_.find(items, {'is_optional': true, 'id': parseInt(value)}) !== undefined);
            setIsReminder(_.find(items, {'is_a_reminder': true, 'id': parseInt(value)}) !== undefined);
            setIsMeeting(_.find(items, {'is_meeting': true, 'id': parseInt(value)}) !== undefined);
            setIsBid(_.find(items, {'is_bid': true, 'id': parseInt(value)}) !== undefined);
            setIsPipeline(_.find(items, {'is_pipeline': true, 'id': parseInt(value)}) !== undefined);
            setActivityType(_.find(items, {'id': parseInt(value)}));
        }
    }

    const handleSearch = async (query) => {
        setContactLoading(true);
        try {
            const response = props?.is_company && props?.company != null ? await promptr.get(`contacts?q=${query}&company=` + props?.company?.id) : await promptr.get(`contacts?q=${query}`);
            if (response.status === 200) {
                console.log('contact results loaded');
                let data = response.data;
                console.log(data);
                setContactResult(data);
                setContactLoading(false);
            }
        } catch (error) {
            console.log('contact results error');
            console.log(error);
        }
    };

    const handleUserSearch = async (query) => {
        setUserLoading(true);
        try {
            const response = await promptr.get(`users?q=${query}&page=1&per_page=50`);
            if (response.status === 200) {
                console.log('user results loaded');
                let data = response.data;
                console.log(data);
                setUserResult(data);
                setUserLoading(false);
            }
        } catch (error) {
            console.log('user results error');
            console.log(error);
        }
    };

    const handleItemSelected = async (option, field) => {
        console.log(option[0]);
        console.log(field);
        setValue(field, option[0].email);
        setCompanies(option[0].companies);
        setSelectedCompany(option[0].companies);
    }

    const setSelectedCompany = (data) => {
        console.log('Selected Company');
        console.log(data);
        if (!_.isEmpty(data) && _.size(data) === 1) {
            console.log('selected Company found ')
            setCompany(data[0].id);
            setValue('company', data[0].id);
        }
    }


    return (

        <div className="modal promptr-modal" id="activity">
            <div className="modal-box bg-white">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="card " style={{border: 'none'}}>
                        <div className="card-title">
                            <h4 className="text-md">
                                Add BD Activity
                            </h4>
                        </div>

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm text-danger">
                                Type of Activity
                            </label>
                            <div className="col-12">
                                <select defaultValue={activityType?.id} onChange={(e) => {
                                    setValue('activity_type', e.target.value);
                                    handleOptionalChange(e.target.value, result?.activity_types);
                                }} required={true}
                                        className="select w-full max-w-xs">
                                    <option disabled value='' selected> Pick one</option>
                                    {renderOptions(result?.activity_types)}
                                </select>
                            </div>
                        </div>
                        { activityType?.is_bid ?
                            <div className="row mb-2 mt-1">
                                <div className="col-12">

                                    <div className="form-check">
                                        <input defaultChecked={true} {...register('send_email_alert')}
                                               className="form-check-input" type="checkbox"
                                               value="1"/>
                                        <label className="form-check-label">
                                            {isMeeting || isBid ? 'Send briefing email 1 day before' : 'Send user alert on activity date'}
                                        </label>
                                    </div>
                                </div>
                            </div> : null}

                        {!isMailshot && !isEvent ?
                            <>


                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm text-danger">Summary of
                                        Activity</label>
                                    <div className="col-12">
                                        <input   {...register('summary')} type="text"
                                                 placeholder="Summary of activity"
                                                 className="form-control"/>
                                    </div>
                                </div>
                            </>
                            : null
                        }

                        {activityType?.is_bid ?
                            <>
                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm">Fee</label>
                                    <div className="col-12">
                                        <input defaultValue={''}  {...register('fee')} type="number"
                                               placeholder="Fee"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm ">Percentage chance</label>
                                    <div className="col-12">
                                        <input defaultValue={''}  {...register('percentage_chance')} type="number"
                                               placeholder="Percentage chance"
                                               className="form-control"/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-form-label col-form-label-sm ">Submission Date</label>
                                    <div className="col-12">
                                        <Controller
                                            control={control}
                                            name='submission_date'
                                            render={({field: {onChange, value, ref}}) => (
                                                <DatePicker
                                                    dateFormat="dd-MMM-yyyy"
                                                    className="form-control datePick"
                                                    placeholderText='Select submission date'
                                                    selected={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </>

                            : null}

                        <div className="row mb-2">
                            <label className="col-form-label col-form-label-sm">Contact</label>
                            {inCompany ?
                                <div className="col-12">
                                    <select className="select w-full max-w-xs" {...register('contact')}>
                                        {renderContacts(props?.company?.contacts, contact)}
                                    </select>
                                </div> :
                                <div className="col-12">
                                    <div className="form-group ">
                                        <AsyncTypeahead
                                            ref={contactInputRef}
                                            className={'form-control-activity'}
                                            filterBy={() => true}
                                            id="activity-contacts"
                                            defaultInpuValue={props?.contact_exists ? props?.contact_name : null}
                                            isLoading={contactLoading}
                                            labelKey={option => `${option?.first_name} ${option?.last_name}`}
                                            minLength={3}
                                            onSearch={handleSearch}
                                            onChange={(selected) => handleItemSelected(selected, 'contact')}
                                            options={contactResult}
                                            placeholder={(props?.contact_exists ? props?.contact_name : null) || "Search for contact.."}
                                            renderMenuItemChildren={(option, props) => (
                                                <div>
                                                    <div className='display-block text-lg'>
                                                        {option?.full_name}
                                                    </div>
                                                    <div>
                                                        <small className='text-gray-600 text-sm font-semibold'>
                                                            {option?.company_names}
                                                        </small>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        {companies != null ?
                            <div className="row mb-3">
                                <label className="col-form-label col-form-label-sm">Contact company</label>
                                <div className="col-12">
                                    <select className="select w-full max-w-xs" {...register('company')}>
                                        <option disabled value='' selected> Pick company</option>
                                        {renderCompanies(companies, company)}
                                    </select>
                                </div>
                            </div> : null
                        }

                        <div className="row mb-3">
                            <label className="col-form-label col-form-label-sm">Who did the BD</label>
                            <div className="col-12">
                                <select className="select w-full max-w-xs" {...register('user')}>
                                    <option disabled value='' selected> Pick user</option>
                                    {renderOptions(result?.users)}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="activity_date"
                                   className="col-4 col-form-label col-form-label-sm">Activity Date</label>
                            <div className="col-8">
                                <Controller
                                    control={control}
                                    defaultValue={new Date()}
                                    name='activity_date'
                                    render={({field: {onChange, value, ref}}) => (
                                        <DatePicker
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control datePick"
                                            placeholderText='Select activity date'
                                            selected={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                            </div>
                        </div>


                        <div className='col-12'>
                            {!isMailshot && !isEvent ?
                                <div className="row mb-3">
                                    <label
                                        className="col-12 col-form-label col-form-label-sm">Topics</label>
                                    <Controller
                                        defaultValue={result?.topics}
                                        control={control}
                                        name="topics"
                                        render={({field: {onChange, value, ref}}) => (
                                            <Select
                                                isSearchable={true}
                                                value={topics}
                                                placeholder="Choose topics"
                                                defaultValue={topics}
                                                onChange={function (val) {
                                                    console.log(val);
                                                    setValue('topics', val.map(c => c.value))
                                                    setTopics(val)
                                                }}
                                                isMulti
                                                name="topics"
                                                options={result?.topics}
                                                className="db-multi-select"
                                                classNamePrefix="select"
                                                menuPlacement={'top'}
                                                noOptionsMessage={({inputValue}) => !inputValue ? noOptionsText : "No results found"}
                                            />
                                        )}/>

                                </div>
                                : null}
                            <div className="row col-12 mb-3">
                                <label htmlFor="topics"
                                       className="col-4 col-form-label col-form-label-sm">{isMailshot ? 'Mailshot' : (isEvent ? 'Event ' : 'Project')}</label>
                                <div className="col-8">
                                    <select className="select w-full max-w-xs" {...register('project')}>
                                        <option disabled value='' selected> Pick one</option>
                                        {renderOptions(projects)}
                                    </select>
                                </div>
                            </div>
                        </div>


                        {activityType?.is_optional ?
                            <div className='col-12'>
                                {!activityType?.is_pipeline ?
                                    <>
                                        <div className="row col-12 mb-3">
                                            <label htmlFor="stage"
                                                   className="col-4 col-form-label col-form-label-sm">Bid Stage</label>
                                            <div className="col-8">
                                                <select className="select w-full max-w-xs" {...register('stage')}>
                                                    <option disabled value='' selected> Pick one</option>
                                                    {renderOptions(result?.stages)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row col-12 mb-3">
                                            <label htmlFor="stage"
                                                   className="col-4 col-form-label col-form-label-sm">Pipeline
                                                Stages</label>
                                            <div className="col-8">
                                                <select className="select w-full max-w-xs" {...register('pipeline')}>
                                                    <option disabled value='' selected> Pick one</option>
                                                    {renderOptions(result?.pipelines)}
                                                </select>
                                            </div>
                                        </div>
                                    </> : null}
                                {!activityType?.is_bid ?
                                    <div className="row col-12 mb-3">
                                        <label htmlFor="stage"
                                               className="col-4 col-form-label col-form-label-sm">Pipeline
                                            Stages</label>
                                        <div className="col-8">
                                            <select className="select w-full max-w-xs" {...register('pipeline')}>
                                                <option disabled value='' selected> Pick one</option>
                                                {renderOptions(result?.pipelines)}
                                            </select>
                                        </div>
                                    </div>
                                    : null}
                                <div className="row col-12 mb-3">
                                    <label htmlFor="confidential"
                                           className="col-4 col-form-label col-form-label-sm">Confidential</label>
                                    <div className="col-8">
                                        <select className="select w-full max-w-xs" {...register('confidential')}>
                                            <option disabled value='' selected> Pick one</option>
                                            {renderOptions(result?.confidentials)}
                                        </select>
                                    </div>
                                </div>
                            </div> : null
                        }
                        {!isMailshot && !isEvent ?
                            <div className="row mb-2">
                                <label className="col-form-label col-form-label-sm">Point of Discussion</label>
                                <div className="col-12">
                            <textarea {...register('discussion')} className="form-control"
                                      placeholder="Point of discussion"></textarea>
                                </div>
                            </div> : null
                        }

                    </div>
                    <div className="modal-action card-bottom">
                        <button type='submit' disabled={isSubmitting}
                                className={isSubmitting ? "btn  bg-green-500 btn-accent loading" : "btn  bg-green-500 btn-accent"}><span
                            className="text-white">Add</span></button>
                        <a href='#' ref={closeModalRef} className="btn btn-default cursor-pointer btn-red">Discard</a>
                    </div>
                </form>
            </div>
        </div>
    );

}


export default Activity;
