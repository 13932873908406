import * as React from "react";
import CompanyProfile from "./CompanyProfile";
import ActivityDetail from "./ActivityDetail";

export default class ActivityRow extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activity: props?.activity,
            activity_item: null,
            options: props?.options,
            show_modal: false,
        }
    }

    closeModal = () => {
        console.log('closing the modal');
        this.setState({
            activity_item: null,
            show_modal: false,
        });
    }

    updateActivity = (item) => {
        this.setState({
            activity: item
        })
    }

    handleRowClick = (activity) => {
        console.log('closing the modal');
        this.setState({
            activity_item: activity,
            show_modal: activity != null,
        });
    }


    render() {

        const {activity,options, show_modal} = this.state;
        return (
            <tr  className="whitespace-nowrap activity-row" style={{borderBottom: '1px solid #3333331f', cursor: 'pointer'}}>
                <td onClick={() => this.handleRowClick(activity)} width='30%' nowrap className="px-3 py-1 text-sm"> {activity.activity_date}
                </td>
                <td onClick={() => this.handleRowClick(activity)}  className="px-2 py-1" style={{wordWrap: 'break-word'}}>
                    <div  className="text-sm text-gray-900 cursor-pointer">{activity.type_name}</div>
                    {activity.summary  !== 'Outlook email correspondence' && (
                        <span className="activity-name text-gray-500">{activity.summary}</span>
                    )}
                </td>
                <ActivityDetail isOpen={show_modal === true} activity={activity} options={options} closeSheetModal={this.closeModal} onActivityUpdate={this.updateActivity}/>
            </tr>
        );
    }
};
