import axios from "axios";
import { useSnackbar } from 'notistack';
import _ from 'lodash';

let baseUrl = process.env.REACT_APP_API_URL ?? _.replace(_.replace(_.replace(window.location.href, '-addin', ''), '?et=', ''), '#', '');

let promptr = axios.create({
    baseURL:  baseUrl + "api/outlook",
    headers: {
        'Content-Type': 'application/json',
    },
});

promptr.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem('tempo_token') ?? localStorage.getItem('access_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export default promptr;

/**
 *
 * @param props
 * @param otp
 * @returns {Promise<boolean>}
 */
export const login = async (props, otp) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    try {
        const response = await promptr.post("auth/login", {
            email: props?.email,
            otp: otp,
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                props.login(responseBody);
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
                return false;
        }

        console.log('Email response');
        console.log(responseBody);

    } catch (error) {
        console.log('Email adding error');
        console.log(error);

        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'error',
        });
        return false;
    }
}

export const postProfile = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {
        const response = await promptr.post("contacts/update", {
            first_name: data.first_name || props?.first_name,
            last_name: data.last_name || props?.last_name ,
            email: data.email || props?.email,
            company: data.company || props?.company,
            job_title: data.job_title || props?.job_title,
            mobile: data.mobile || props?.mobile,
            address: data.address || props?.address,
            tags: data.tags || null,
            companies: data.companies || null,
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                props?.onUpdate(data.email || props?.email);
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
               return false;
        }

        console.log('Contact response');
        console.log(responseBody);

    } catch (error) {
        console.log('Contact adding error');
        console.log(error);
        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'success',
        });
        return true;
    }
}

/**
 *
 * @param data
 * @param props
 * @returns {Promise<boolean>}
 */
export const postActivity = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {

        const response = await promptr.post("contacts/activity", {
            summary: data.summary,
            contact: data.contact || props?.contact_email,
            activity_type: data.activity_type || defaultActivityType?.id,
            activity_date: data.activity_date || defaultDate,
            confidential: data.confidential || null,
            topic: data.topic || null,
            user: data.user || props?.user_email,
            discussion: data.discussion || null,
            project: data.project || null,
            send_email_alert: data.send_email_alert || false
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                props?.onUpdate(data.contact || props?.contact_email);
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
                return false;
            default:
                enqueueSnackbar('An error occurred while sending your request, please reload the add in or consult our help center', {
                    variant: 'error',
                });
                return false;
        }

    }catch (error) {
        console.log('Contact adding error');
        console.log(error);
        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'error',
        });
    }
}

/**
 *
 * @param data
 * @param props
 * @returns {Promise<boolean>}
 */
export const postContact = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {
        const response = await promptr.post("contacts/create", {
            first_name: data.first_name || props?.first_name,
            last_name: data.last_name || props?.last_name,
            email: data.email || props?.email,
            company: data.company || props?.company,
            job_title: data.job_title || props?.job_title,
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')},
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
                return false;
        }

        console.log('Contact response');
        console.log(responseBody);

    } catch (error) {
        console.log('Contact adding error');
        console.log(error);
        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'success',
        });

        return false;
    }
}

/**
 *
 * @param data
 * @param props
 * @returns {Promise<boolean>}
 */
export const postCompany = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {
        const response = await promptr.post("companies/create", {
            company_name: data.company_name,
            organisation: data.organisation,
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
               return false;
        }

        console.log('Contact response');
        console.log(responseBody);

    } catch (error) {
        console.log('Contact adding error');
        console.log(error);
        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'error',
        });

        return false;
    }
}

/**
 *
 * @param data
 * @param props
 * @returns {Promise<boolean>}
 */
export const postUser = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {
        const response = await promptr.post("users/update", {
            first_name: data.first_name || props?.first_name,
            last_name: data.last_name || props?.last_name,
            email: data.email || props?.email,
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
        });

        let responseBody = response.data;

        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                return false;
        }

        console.log('User response');
        console.log(responseBody);

    } catch (error) {
        console.log('User adding error');
        console.log(error);

        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'success',
        });

        return false;
    }
}

/**
 *
 * @param data
 * @param props
 * @returns {Promise<boolean>}
 */
export const postEmail = async (data, props) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    try {
        const response = await promptr.post("users/email", {
            subject: data.subject,
            email: data.email,
            body: data.body
        }, {
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('access_token')}
        });

        let responseBody = response.data;
        switch (response.status) {
            case 200:
                enqueueSnackbar(responseBody.message, {
                    variant: 'success',
                });
                return true;
            case 422:
                enqueueSnackbar(responseBody.message, {
                    variant: 'error',
                });
                return false;
        }

        console.log('Email response');
        console.log(responseBody);

    } catch (error) {
        console.log('Email adding error');
        console.log(error);
        enqueueSnackbar(error?.response?.data?.message || 'An error occurred while sending your request, please reload the add in or consult our help center', {
            variant: 'error',
        });

    }
}
